import { NhostClient } from '@nhost/vue'
import { subdomain, region } from '../env.js'

let nhostInstancia = null
let isInitialized = false

async function nhostAuth() {
  if (isInitialized && nhostInstancia) return nhostInstancia

  try {
    nhostInstancia = new NhostClient({ subdomain, region })

    // Aguarde a sessão estar disponível
    await nhostInstancia.auth.getSession()

    isInitialized = true
    return nhostInstancia
  } catch (error) {
    console.error('❌ Erro ao inicializar o Nhost:', error)

    // Retry com setTimeout após 2 segundos
    return new Promise(resolve => {
      setTimeout(async () => {
        const nhost = await nhostAuth()
        resolve(nhost)
      }, 2000)
    })
  }
}

export { nhostAuth }
